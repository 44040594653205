import { Button, SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { useChatContext, useCustomKplContext } from '../../../contexts';
import { CommonMessage } from '../message';
import { useEffect, useState } from 'react';

export const ConfirmCreateKpl = () => {
    const { handleSave, isKplCreated, isLoading, name, errorText, navigateToKpl, isKplLoading } = useCustomKplContext();
    const { refetchCurrentUserConfig, refetchApplicationConfigs } = useChatContext();
    const [isKplsLoading, setIsKplsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isKplCreated) return;

        refetchCurrentUserConfig().finally(() => {
            refetchApplicationConfigs();
            setIsKplsLoading(false);
        });
    }, [isKplCreated]);

    return !isKplCreated ? (
        <div className="flex flex-col gap-y-3 w-fit">
            <p>👇 Please click the button below to proceed with KPL creation.</p>
            <Button
                className="bg-B-500 hover:bg-B-400 text-white outline-none border-none py-2 px-4 rounded-md w-full flex items-center disabled:bg-N-400 disabled:pointer-events-none"
                onClick={() => handleSave()}
                isLoading={isLoading}
                iconBefore={isLoading ? '' : 'ri-sparkling-line'}
            >
                Proceed with KPL creation
            </Button>
            {errorText !== '' && (
                <p className="text-R-600 text-xs">
                    <i>{errorText}</i>
                </p>
            )}
        </div>
    ) : (
        <CommonMessage
            text={''}
            content={
                isKplsLoading || isKplLoading ? (
                    <div className="max-w-[80%] sm:max-w-full flex items-center gap-x-3">
                        <SpinLoader />
                        <span>Preparing your KPL.</span>
                    </div>
                ) : (
                    <div className="max-w-[80%] sm:max-w-full">
                        <p>{`Perfect! Your ${name} has been successfully created.`}</p>
                        <span>
                            By navigating to the Explore KPLs -{'>'}
                            <span className="mx-1">{name}</span>
                            you can start a conversation with your own KPL.
                            <br />
                            Or by clicking on this Link
                            <span className="text-B-700 mx-1 cursor-pointer" onClick={() => navigateToKpl()}>
                                Navigate to the custom KPL
                            </span>
                            you can navigate to the KPL as well.
                        </span>
                    </div>
                )
            }
        />
    );
};
