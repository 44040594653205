import { Avatar, Button, Tag, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { columnsArrayType } from '../components/common/table';
import { ALL_VALUE_CAPITALIZED, EXTERNAL_USERS_REQUEST_STATUS, PAGE_SIZE, USER_STATUS_COLORS } from '../constants';
import { formattedDate, getInitials, truncate, uniqueId } from '../utils';
import { IFilterExternalUserCriteria } from '../components/common';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_KPL_REQUEST_USERS } from '../graphql';
import { KplRequestUserStatus, UserStatus } from '../__generated__/graphql';

export const useExternalUserManageTable = (filterExternalUserCriteria?: IFilterExternalUserCriteria) => {
    const [isApproveModelOpen, setIsApproveModelOpen] = useState<boolean>(false);
    const [isRejectModelOpen, setIsRejectModelOpen] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [queryPage, setQueryPage] = useState(1);
    const [dataRemaining, setDataRemaining] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
    const tableElementId = 'external-user-table-wrapper';
    const [previousScrollTop, setPreviousScrollTop] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [tableLoadingText, setTableLoadingText] = useState('Loading...');
    const [selectedProfile, setSelectedProfile] = useState<any>();
    const [reloading, setReloading] = useState(false);
    const [getAllKplRequestUsers, { loading, fetchMore, refetch }] = useLazyQuery(GET_ALL_KPL_REQUEST_USERS);

    const refetchData = async () => {
        setReloading(true);
        const { data } = await refetch();
        resetTableData(data?.getAllKPLRequestUsers?.users || []);
        if (data) {
            setReloading(false);
        }
    };

    const resetTableData = (tableData: any[]) => {
        setTableData(tableData);
        setDataRemaining(true);
        setQueryPage(1);
    };

    const loadMoreData = async () => {
        setTableLoadingText('Loading more Users...');
        // If data is loading or isLoadingMore is true, return
        if (!tableData || loading || isLoadingMore) return;

        // Use the nextPage state variable to calculate the page
        const nextPage = queryPage + 1;

        if (totalPages >= nextPage) {
            // Set isLoadingMore to true
            setIsLoadingMore(true);
            await fetchMore({
                variables: {
                    input: {
                        page: nextPage,
                        pageSize: PAGE_SIZE,
                        keyword: debouncedSearchTerm,
                        requestStatus:
                            filterExternalUserCriteria?.requestStatus == ALL_VALUE_CAPITALIZED
                                ? undefined
                                : (filterExternalUserCriteria?.requestStatus as KplRequestUserStatus),
                        userStatus:
                            filterExternalUserCriteria?.activeStatus == ALL_VALUE_CAPITALIZED
                                ? undefined
                                : (filterExternalUserCriteria?.activeStatus as UserStatus),
                    },
                },
            }).then(({ data: newData }) => {
                if (Number(newData?.getAllKPLRequestUsers?.totalPages) === nextPage) {
                    setDataRemaining(false);
                }

                if (newData && newData.getAllKPLRequestUsers) {
                    setTableData([...tableData, ...newData?.getAllKPLRequestUsers?.users]);

                    // Update the queryPage state variable
                    setQueryPage(nextPage);
                }

                // Set isLoadingMore back to false
                setIsLoadingMore(false);
                setTableLoadingText('Loading...');
            });
        }
    };

    const handleScroll = useCallback(
        (e: Event) => {
            const target = e.target as HTMLDivElement;
            const { scrollTop, scrollHeight, clientHeight } = target;
            const isScrollingDown = scrollTop > previousScrollTop;
            setPreviousScrollTop(scrollTop);
            const scrollPercentage = scrollTop + clientHeight >= scrollHeight - 50;
            if (isScrollingDown && scrollPercentage && dataRemaining) {
                loadMoreData();
            }
        },
        [loadMoreData, dataRemaining]
    );

    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollableElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll, scrollableRef]);

    useEffect(() => {
        if (!isLoadingMore && tableData?.length) {
            heightLightLatestLoadedRow();
        }
    }, [isLoadingMore]);

    const heightLightLatestLoadedRow = () => {
        if (queryPage > 1) {
            const tableRows = document.querySelectorAll('table tbody tr');
            const newlyLoadedPageIndex = (queryPage - 1) * 25;

            for (let i = newlyLoadedPageIndex; i < tableData.length; i++) {
                tableRows[i].classList.add('blink-2');
            }
        }

        const listingTableWrapper = document.getElementById(tableElementId);

        if (listingTableWrapper) {
            const currentScrollPosition = listingTableWrapper.scrollTop;
            const newScrollPosition = currentScrollPosition + 250;
            const maxScroll = listingTableWrapper.scrollHeight - listingTableWrapper.clientHeight;
            const clampedScrollPosition = Math.min(maxScroll, Math.max(0, newScrollPosition));

            listingTableWrapper.scrollTo({
                top: clampedScrollPosition,
                behavior: 'smooth',
            });
        }
    };
    const scrollToTop = () => {
        try {
            const listingTableWrapper = document.getElementById(tableElementId);
            if (listingTableWrapper) {
                listingTableWrapper?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        } catch (error) {
            throw new Error('Prompts Table is not found');
        }
    };

    //fetch data
    const fetchTableData = () => {
        setTableLoadingText('Loading more Users...');
        getAllKplRequestUsers({
            variables: {
                input: {
                    page: 1,
                    pageSize: PAGE_SIZE,
                    keyword: debouncedSearchTerm,
                    requestStatus:
                        filterExternalUserCriteria?.requestStatus == ALL_VALUE_CAPITALIZED
                            ? undefined
                            : (filterExternalUserCriteria?.requestStatus as KplRequestUserStatus),
                    userStatus:
                        filterExternalUserCriteria?.activeStatus == ALL_VALUE_CAPITALIZED
                            ? undefined
                            : (filterExternalUserCriteria?.activeStatus as UserStatus),
                },
            },
            onCompleted: ({ getAllKPLRequestUsers }) => {
                setTotalPages(Number(getAllKPLRequestUsers?.totalPages));
                resetTableData(getAllKPLRequestUsers?.users || []);
                setTableLoadingText('Loading...');
            },
        });
    };

    // Re fetch tableData query on filter changed
    useEffect(() => {
        //scroll to top when filter is applied
        scrollToTop();
        fetchTableData();
    }, [debouncedSearchTerm, filterExternalUserCriteria]);

    const normalizeStatus = (status: string) => {
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    };

    const tableColumnDefinition: columnsArrayType[] = useMemo(() => {
        return [
            {
                id: '1',
                Header: 'User Name',
                showHeader: true,
                accessor: 'name',
                isSortable: true,
                width: 200,
                minWidth: 150,
                isCustomizable: false,
                Cell: (props: any) => {
                    const { row } = props || {};
                    const user = row?.original?.user;
                    const userName = `${user?.firstName} ${user?.lastName}`;
                    const email = user?.email;

                    return (
                        <div className="flex items-center gap-x-4">
                            <Avatar
                                className="cursor-pointer"
                                shape="circle"
                                size="md"
                                type={'text'}
                                icon="ri-user-3-line"
                                textLabel={getInitials(userName)}
                            />
                            <div className="flex flex-col">
                                <Tooltip
                                    id={uniqueId()}
                                    title={truncate(userName, 25)}
                                    tooltipType="minimal"
                                    variant="dark"
                                    place="right"
                                >
                                    <p className="text-N-900 font-500 max-w-[200px] truncate">{userName}</p>
                                </Tooltip>

                                <Tooltip
                                    id={uniqueId()}
                                    title={truncate(email, 25)}
                                    tooltipType="minimal"
                                    variant="dark"
                                    place="right"
                                >
                                    <p className="text-N-600 text-sm max-w-[200px] truncate">{email}</p>
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
            },
            {
                id: '3',
                Header: 'Request Status',
                showHeader: true,
                accessor: 'requestStatus',
                isSortable: false,
                width: 120,
                minWidth: 120,
                alignment: 'left',
                isCustomizable: false,
                Cell: (props: any) => {
                    const { row } = props || {};
                    const status = row?.original?.status;
                    const getStatusAppearance = (status: string) => {
                        const normalizedStatus = normalizeStatus(status);
                        return EXTERNAL_USERS_REQUEST_STATUS[normalizedStatus] || EXTERNAL_USERS_REQUEST_STATUS.Pending;
                    };
                    return status ? (
                        <Tag appearance={getStatusAppearance(status)} className="capitalize" label={status} />
                    ) : (
                        <Tag appearance={'amber'} className="capitalize" label={'N/A'} />
                    );
                },
            },
            {
                id: '4',
                Header: 'Active Status',
                showHeader: true,
                accessor: 'activestatus',
                isSortable: false,
                width: 120,
                minWidth: 120,
                alignment: 'left',
                isCustomizable: false,
                Cell: (props: any) => {
                    const { row } = props || {};
                    const status = row?.original?.user?.status;
                    const getStatusAppearance = (status: string) => {
                        const normalizedStatus = normalizeStatus(status);
                        return USER_STATUS_COLORS[normalizedStatus] || USER_STATUS_COLORS.Pending;
                    };

                    return status ? (
                        <Tag appearance={getStatusAppearance(status)} className="capitalize" label={status} />
                    ) : (
                        <Tag appearance={'neutral'} className="capitalize" label={'N/A'} />
                    );
                },
            },
            {
                id: '5',
                Header: 'Approved/Rejected Date',
                showHeader: true,
                accessor: 'activetiondate',
                isSortable: true,
                width: 120,
                minWidth: 120,
                alignment: 'left',
                isCustomizable: false,
                sortType: (rowA: any, rowB: any, columnId: any, desc: any) => {
                    const dateA = new Date(rowA?.original?.user?.activatedDate).getTime();
                    const dateB = new Date(rowB?.original?.user?.activatedDate).getTime();
                    return dateB - dateA;
                },
                Cell: (props: any) => {
                    const { row } = props || {};
                    const activatedDate = row?.original?.user?.activatedDate;
                    return (
                        <p className="text-N-900 font-500 max-w-[200px] truncate">
                            {activatedDate ? formattedDate(activatedDate) : 'Not Activated yet'}
                        </p>
                    );
                },
            },
            {
                id: '6',
                Header: 'Action',
                showHeader: true,
                accessor: 'action',
                isSortable: false,
                width: 150,
                minWidth: 150,
                isCustomizable: false,
                alignment: 'left',
                headerPadding: '12px 18px',
                Cell: (props: any) => {
                    const { row } = props || {};
                    return (
                        <div className="flex items-center justify-between">
                            {row?.original?.status === KplRequestUserStatus.Pending ? (
                                <>
                                    <Button
                                        view="ghost"
                                        appearance="primary"
                                        size="small"
                                        className="whitespace-nowrap"
                                        onClick={() => {
                                            setSelectedProfile(row?.original);
                                            setIsApproveModelOpen(true);
                                        }}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        view="ghost"
                                        appearance="primary"
                                        size="small"
                                        className="ms-3"
                                        onClick={() => {
                                            setSelectedProfile(row?.original);
                                            setIsRejectModelOpen(true);
                                        }}
                                    >
                                        Reject
                                    </Button>
                                </>
                            ) : (
                                <p className="ps-3">Action performed</p>
                            )}
                        </div>
                    );
                },
            },
        ];
    }, []);

    return {
        tableColumnDefinition,
        tableData,
        searchTerm,
        setSearchTerm,
        selectedProfile,
        scrollableRef,
        tableElementId,
        loading,
        reloading,
        isLoadingMore,
        tableLoadingText,
        isApproveModelOpen,
        setIsApproveModelOpen,
        isRejectModelOpen,
        setIsRejectModelOpen,
        refetchData,
        setReloading,
    };
};
